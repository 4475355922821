@import '../../variables';

.iconImage {
  background-size: cover;
  background-position: center;
  border: 2px solid @color-light-grey;
  border-radius: 7px;
  cursor: pointer;
  height: 40px;
  margin: 7px auto;
  width: 40px;

  &:hover {
    border-color: @nami-light-gray;
  }
}

.button {
  background: @color-white !important;
  border: 1.5px solid @color-light-grey !important;
  border-radius: 10px !important;
  cursor: pointer;
  height: 45px !important;
  width: 45px !important;
  padding: 0px !important;
}
