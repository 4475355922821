@import '../../variables';

.container {
  padding-bottom: 15px;
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 40vh;
  min-height: 500px;
}

.image {
  margin-bottom: 40px;
}

.subtitle {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: @breakpoint-md) {
  .container {
    height: 60vh;
    min-height: 350px;
  }
}
