.headerIPhone,
.headerIPad,
.contentIPhone,
.contentIPad,
.title,
.body,
.button {
  font-family: 'Helvetica', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

// General
.headerIPhone {
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  height: 100%;
  border-radius: 20px;
  font-family: 'Helvetica', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.headerIPad {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  height: 100%;
  border-radius: 20px;
}

.contentIPhone {
  width: 100%;
  padding: 15px;
  overflow-y: scroll;
}

.contentIPad {
  width: 100%;
  padding: 15px;
  overflow-y: scroll;
}

.button {
  cursor: text !important;
  margin-bottom: 0.3rem !important;
  margin-top: 0.3rem !important;
}

.transparentButton {
  margin: 1rem auto;
  font-weight: bold;
  text-align: center;
}

// END Genreal

.close {
  margin: 15px 15px 0 0;
  text-align: right;
  font-weight: bold;
}

.title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}

.body {
  font-weight: 700;
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.purchaseTerms {
  margin: 1rem 0;
  text-align: left;
}
