@import '../../variables';

.container {
  cursor: pointer;
}

.label {
  margin-top: 11px;
  word-break: break-all;
}

.title {
  word-break: break-all;
}
