.bar {
  list-style: none;
  padding-left: 10px;
}
.bar > li {
  position: relative;
}

.bar > li:before {
  content: '\25CF';
  margin-right: 10px;
  font-size: 20px;
  color: grey;
}

.bar > li:after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  border-left: 1px solid grey;
  margin-left: 6px;
  height: 100%;
}
.bar > li:first-of-type:after {
  top: 50%;
}
.bar > li:last-of-type:after {
  top: -50%;
}

.barFirst {
  list-style: none;
  padding-left: 10px;
}
.barFirst > li {
  position: relative;
}

.barFirst > li:before {
  content: '\25CF';
  margin-right: 10px;
  font-size: 20px;
  color: grey;
}

.barFirst > li:after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  border-left: 1px solid transparent;
  margin-left: 5px;
  height: 100%;
}
.barFirst > li:first-of-type:after {
  top: 50%;
}
.barFirst > li:last-of-type:after {
  top: -50%;
}
