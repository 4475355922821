@import '../../variables';

.sidebar {
  background: @color-white;
  z-index: 1000000 !important;
  padding-bottom: 100px;
}

.sidebarPushDown {
  top: 70px !important;
  transition: all 0.2s !important;
}

.accordion {
  border-radius: 0 !important;
}
