@import '../../variables';

.wrapper {
  background-color: @background;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.containerWrapper {
  padding: 100px 0;
}

.container {
  margin: 0 auto;
  max-width: 480px;
  padding: 0 20px;
  width: 100%;
}

@media only screen and (max-width: @breakpoint-sm) {
  .wrapper {
    height: 100%;
  }

  .containerWrapper {
    padding: 20px 0;
  }
}

@media only screen and (max-width: @breakpoint-xs) {
  .wrapper {
    height: 180vh;
  }
}
