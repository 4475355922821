@import 'semantic-ui-less/semantic.less';
@import 'antd/dist/antd.less';
@import './variables';

#root {
  height: 100%;
}

.nami-container {
  height: 100%;
  padding: 0 !important;
}

.nami-layout-content {
  margin: 24px 16px;
  padding: 12px 56px;
  min-height: 100vh;
}

@media only screen and (max-width: @breakpoint-md) {
  .nami-layout-content {
      padding: 0;
  }
}

.settings-content-container {
  margin-left: 256px !important;
  margin-top: 72px;
  padding: 12px 56px;
  min-height: 100vh;
}

.content-container {
  margin-left: 60px;
  padding: 15px;
}

.main-container {
  max-width: 1360px;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
}

.full-main-container {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  padding: 0px;
}

.full-main-container-white {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  padding: 0px;
  background-color: #ffffff;
}

@media only screen and (max-width: @breakpoint-md) {
  .content-container {
    margin-left: 0;
  }

  .settings-content-container {
    margin-left: 0;
  }
}

.header-mobile-container {
  background: @color-white;
}

//////////////////////////////////////Theme Overwrite/////////////////////////////
// Side Menu Overwrite
.ui.vertical.menu .active.item {
  background: rgba(0, 0, 0, 0.3);
}
// Button Overwrite
.ui.basic.button {
  color: @color-primary !important;
}
.ui.active.nami-button {
  background-color: @color-primary !important;
  color: @color-white !important;
}

.nami-primary-button {
  background-color: @color-primary !important;
  color: @color-white !important;
}

.ui.buttons.active {
  color: @color-white !important;
}

.ui.nami-buttons > .nami-active {
  color: @color-white;
  background-color: @color-primary !important;
}

.ui.basic.buttons .active.button {
  background-color: @color-primary !important;
  color: @color-white !important;
}
.ui.basic.left.floated.buttons {
  border: 1px solid @color-primary;
}

.ui.buttons.ui.button {
  color: @color-primary !important;
}

.destructive-button {
  background-color: @color-danger !important;
  color: @color-white !important;
  &:hover,
  &:focus {
    background: @color-danger-hover;
  }
}

// Dropdown Overwrite
.ui.selection.dropdown:focus {
  border-color: @color-primary;
}

// Radio overwrite

.nami-radio input[type='radio'] {
  opacity: 0 !important;
}

// Import Overwrite
.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  background: @color-light-grey;
}
// Will overwrite checbox
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: @color-primary !important;
}
.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before,
.ui.slider.checkbox input:checked:focus ~ .box:before,
.ui.slider.checkbox input:checked:focus ~ label:before {
  background-color: @color-primary !important;
}
// Will overwrite field default errors
.ui.form .field.error .input,
.ui.form .field.error label,
.ui.form .fields.error .field .input,
.ui.form .fields.error .field label,
.ui.form .field .prompt.label,
.ui.form .field.error input:not([type]),
.ui.form .field.error input[type='date'],
.ui.form .field.error input[type='datetime-local'],
.ui.form .field.error input[type='email'],
.ui.form .field.error input[type='file'],
.ui.form .field.error input[type='number'],
.ui.form .field.error input[type='password'],
.ui.form .field.error input[type='search'],
.ui.form .field.error input[type='tel'],
.ui.form .field.error input[type='text'],
.ui.form .field.error input[type='time'],
.ui.form .field.error input[type='url'],
.ui.form .field.error select,
.ui.form .field.error textarea,
.ui.form .fields.error .field input:not([type]),
.ui.form .fields.error .field input[type='date'],
.ui.form .fields.error .field input[type='datetime-local'],
.ui.form .fields.error .field input[type='email'],
.ui.form .fields.error .field input[type='file'],
.ui.form .fields.error .field input[type='number'],
.ui.form .fields.error .field input[type='password'],
.ui.form .fields.error .field input[type='search'],
.ui.form .fields.error .field input[type='tel'],
.ui.form .fields.error .field input[type='text'],
.ui.form .fields.error .field input[type='time'],
.ui.form .fields.error .field input[type='url'],
.ui.form .fields.error .field select,
.ui.form .fields.error .field textarea,
.ui.selection.dropdown.error,
.ui.dropdown.error,
.ui.dropdown.error > .default.text,
.ui.dropdown.error > .text {
  background: @color-white;
  border-color: @color-danger-hover !important;
  color: @color-danger-hover !important;
}

.credentials_are_set > input::placeholder {
  color: @color-black !important;
}

// Table Overwrite
.table-header {
  background-color: @background;
  color: @color-primary-dark !important;
}

// Modal Overwrite
.ui.dimmer,
.ui.overlay.sidebar {
  z-index: 2147483005 !important;
}
// Tabs overwrite
.ui.secondary.pointing.menu .active.item,
.ui.secondary.pointing.menu .active.item:hover {
  border-color: @color-primary;
  color: @color-primary;
}

// Label Overwrite
.ui {
  &.yellow,
  &.green {
    &.labelTextColor {
      color: @color-primary-dark !important;
    }
  }
}

// Segment Overwite
.ui.segment {
  border: none !important;
}

// Card Overwrite
.ui.card {
  box-shadow: 0 2px 10px 0 rgba(51, 51, 79, 0.02) !important;
}

//////////////////////////////////////Toast CSS Overwite/////////////////////////////
.Toastify__toast--success {
  color: @color-primary-dark;
}
.Toastify__toast--error {
  color: @color-primary-dark;
}
.Toastify__toast--info {
  color: @color-primary-dark;
}
.Toastify__toast--warning {
  color: @color-primary-dark;
}
////////////////////////////Date and Calendar Picker Overwrite/////////////////////

.SingleDatePicker_picker {
  z-index: 9999;
}

.DateInput_input__focused {
  border-bottom: 2px solid @color-primary;
}

.DateInput__small {
  width: 100px !important;
}

.CalendarDay__today {
  background: @color-lighter-grey;
}

.CalendarDay__selected_span {
  background: @color-primary; //background
  color: @color-white; //text
  border: 1px solid @color-primary-hover; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: @color-primary;
  color: @color-white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: @color-primary;
  color: @color-white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span {
  background: @color-primary-hover;
  color: @color-white;
}

// Antd Overwrite DatePicker Range

@media(max-width: 576px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}

// Antd Overwrite Select
.dropdown-image.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
  padding: 5px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}
// Drawer top overwrite
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
  border-bottom: 1px solid lightgrey;
}

//Overwrite switch selected color for campaigns page
.campaignSwitch.ant-switch-checked {
  background-color: @nami-green;
}
.campaignSwitch .ant-switch-checked.ant-switch-disabled {
  background-color: @nami-green;
}

.code-text-styling {
  font-family: 'Overpass Mono',
    'SFMono-Regular',
    'Consolas',
    'Liberation Mono',
    Menlo,
    Courier,
    monospace;
}

//Override scroll behavior on select table
.horizontalScrollTable {
  /* width */
  ::-webkit-scrollbar {
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: @nami-smoke;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: @nami-light-gray;
  }
}

//Override scroll behavior on select drawer
.verticalScrollDrawer {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: @nami-smoke;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: @nami-light-gray;
  }
}

.hover-mui-icon {
  :hover {
    fill: @color-primary;
  }
}

.select-addon-after {
  width: 60px;
}

.special-modal-confirm {
  width: 540px !important;
}

.transform-component-module_wrapper__SPB86 {
  height: 100% !important;
  width: 100% !important;
  cursor: move;
}

.transform-component-module_content__FBWxo {
  height: 100% !important;
  width: 100% !important;
  cursor: move;
}
