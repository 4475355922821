@import '../../variables';

.dragAndDropContainer {
  text-align: center;
  background: @background;
  border-radius: 3px;
  border: 3px dashed @color-light-grey;
  overflow: hidden;
  position: relative;
  &:focus,
  &:hover,
  &:active {
    border: 3px dashed @color-primary;
  }
}

.dragAndDrop {
  position: absolute;
  top: 50%;
  padding: 21px;
  transform: translateY(-50%);
  text-align: center;
}

.editorContainer {
  width: 100%;
  height: 600px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.title {
  font-size: 12px;
  margin-top: 0;
}

.description {
  font-size: 12px;
}

.image {
  margin-bottom: 5px;
}

@media only screen and (max-width: @breakpoint-md) {
  .editorContainer {
    width: 100%;
    height: 450px;
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100% !important;
    margin: 0 !important;

    width: 100% !important;
    border-radius: 0 !important;
  }
  .modalHeader {
    display: none !important;
    font-size: 18px;
  }
}

@media only screen and (max-height: 866px) {
  .editorContainer {
    width: 100%;
    height: 450px;
  }
}

@media only screen and (max-height: 731px) {
  .editorContainer {
    width: 100%;
    height: 400px;
  }
}

@media only screen and (max-width: 320px) {
  .editorContainer {
    width: 100%;
    height: 300px;
  }
}

@media only screen and (max-height: 568px) {
  .editorContainer {
    width: 100%;
    height: 300px;
  }
}
