.df {
  display: flex;
}

.ac {
  align-items: center;
}

.jc {
  justify-content: center;
}

.jfe {
  justify-content: flex-end;
}

.jsb {
  justify-content: space-between;
}

.ps-rl {
  position: relative;
}

.c-cross {
  cursor: crosshair;
}

.canvas-wrapper {
  border-radius: 3px;
  overflow: hidden;
  height: 294px;
}

.c-resize {
  cursor: ew-resize;
}

.c-grab {
  cursor: grab;
}

.opacity-overlay {
  position:absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px
}

.opacity-bg {
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  overflow: hidden
}

.handle {
  position: absolute;
  border: 4px solid white;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0,0,0,.5);
  width: 20px;
  height: 20px;
  z-index: 1000;
  cursor: grab;
}

.gradient-handle-wrap {
  position: absolute;
  z-index: 10000;
  padding-left: 2px;
  padding-right: 2px;
  top: 4px;
  cursor: pointer;
}

.gradient-handle {
  border: 4px solid white;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0,0,0,.5);
  width: 20px;
  height: 20px;
}

.npe {
  pointer-events: none;
}

.degree-input {
  height: 24px;
  border-radius: 4px;
  border: none;
  outline: none;
  text-align: center;
  width: 34px;
  color: rgb(50, 49, 54);
  font-size: 13px;
  background: transparent;
}

.input-wrap {
  height: 32px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 2px;

  outline: none;
  color: black;
  text-align: center;
}

.input-label {
  text-align: center;
  line-height: 1.2;
  font-weight: 500;
  color: rgb(86,86,86);
  font-size: 11px;
}

.control-btn {
  padding-left: 8px;
  padding-right: 8px;
  /* height: 100%; */
  line-height: 1;
  border-radius: 1px;
  font-weight: 500;
  color: rgb(86,86,86);
  font-size: 12px;
  height: 24px;
  transition: all 160ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-wrap {
  width: 330px;
  margin-left: -18px;
  padding: 3px;
  margin-top: 2px
}

.bar-wrap-inner {
  width: 324px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: crosshair;
}

.control-btns-wrap {
  height: 28px;
  background: #f0f0f0;
  border-radius: 2px;
  padding: 2px;
}

.input-dropdown {
  position: absolute;
  right: -2px;
  top: 34px;
  padding: 5px;
  background: #f0f0f0;
  z-index: 100000000;
  border-radius: 6px;
  box-shadow: 1px 1px 14px 1px rgba(0,0,0,.25)
}

.input-dropdown::before {
  position: absolute;
  content: '';
  right: 12.5px;
  top: -3px;
  width: 9px;
  height: 9px;
  background: #f0f0f0;
  transform: rotate(45deg);
  z-index: 0;
  box-shadow: -1px -2px 8px rgba(0,0,0,.15);
}
