@import './variables';

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/GilroyRegular/font.woff2') format('woff2'),
    url('./fonts/GilroyRegular/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/GilroyRegularItalic/font.woff2') format('woff2'),
    url('./fonts/GilroyRegularItalic/font.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/GilroyMedium/font.woff2') format('woff2'),
    url('./fonts/GilroyMedium/font.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/GilroyMediumItalic/font.woff2') format('woff2'),
    url('./fonts/GilroyMediumItalic/font.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/GilroySemiBold/font.woff2') format('woff2'),
    url('./fonts/GilroySemiBold/font.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/GilroySemiBoldItalic/font.woff2') format('woff2'),
    url('./fonts/GilroySemiBoldItalic/font.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass Mono';
  src: url('./fonts/OverpassMonoRegular/OverpassMono-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
  touch-action: manipulation;
}

body {
  margin: 0;
  padding: 0;
  font-variant: none;
  background-color: @background !important;
  line-height: 1.4285em;
  font-family: Gilroy, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Gilroy, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ui {
  font-family: Gilroy, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

code {
  font-family: 'Overpass Mono',
    'SFMono-Regular',
    'Consolas',
    'Liberation Mono',
    Menlo,
    Courier,
    monospace;
}

a {
  color: @color-primary;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
