@import '../../variables';

.title {
  color: @color-danger !important;
}

.button {
  background-color: @color-danger !important;
  color: @color-white !important;
  &:hover {
    background-color: @color-danger-hover !important;
  }
}

.paragraph {
  font-weight: bold;
}
