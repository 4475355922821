@import '../../variables';

.tiny {
  width: 16px;
  height: auto;
}

.small {
  width: 20px;
  height: 20px;
}

.medium-small {
  width: 40px;
  height: auto;
}

.medium {
  width: 52px;
  height: auto;
}

.medium-large {
  width: 80px;
  height: auto;
}

.large {
  width: 104px;
  height: auto;
}

.xlarge {
  width: 156px;
  height: auto;
}

.massive {
  width: 208px;
  height: auto;
}

.huge {
  width: 416px;
  height: auto;
}

.fluid {
  width: 100%;
  height: auto;
}

.default {
  path {
    fill: @color-white;
  }
  circle {
    fill: @color-white;
  }
  rect {
    fill: @color-white;
  }
  text {
    fill: @color-white;
  }
  ellipse {
    fill: @color-white;
  }
}

.primary {
  path {
    fill: @color-primary;
  }
  circle {
    fill: @color-primary;
  }
  rect {
    fill: @color-primary;
  }
  text {
    fill: @color-primary;
  }
  ellipse {
    fill: @color-primary;
  }

  &:hover {
    path {
        fill: @color-primary-hover;
      }

      circle {
        fill: @color-primary-hover;
      }

      rect {
        fill: @color-primary-hover;
      }

      text {
        fill: @color-primary-hover;
      }

      ellipse {
        fill: @color-primary-hover;
      }
  }
}

.secondary {
  path {
    fill: @color-primary-hover;
  }

  circle {
    fill: @color-primary-hover;
  }

  rect {
    fill: @color-primary-hover;
  }

  text {
    fill: @color-primary-hover;
  }

  ellipse {
    fill: @color-primary-hover;
  }
}

.navy {
  path {
    fill: @color-primary-dark;
  }
  circle {
    fill: @color-primary-dark;
  }
  rect {
    fill: @color-primary-dark;
  }
  text {
    fill: @color-primary-dark;
  }
  ellipse {
    fill: @color-primary-dark;
  }

    &:hover {
      path {
        fill: @color-primary;
      }

      circle {
        fill: @color-primary;
      }

      rect {
        fill: @color-primary;
      }

      text {
        fill: @color-primary;
      }

      ellipse {
        fill: @color-primary;
      }
    }
}

.lightGray {
  path {
    fill: @color-light-grey;
  }

  circle {
    fill: @color-light-grey;
  }

  rect {
    fill: @color-light-grey;
  }

  text {
    fill: @color-light-grey;
  }

  ellipse {
    fill: @color-light-grey;
  }
}

.noPadding {
  padding: 0 !important;
}
